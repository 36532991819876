import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import './App.css';

const Landing = () => {
  const { currentUser, loading, login } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading) {
      if (currentUser) {
        navigate("/home");
      }
    }
  }, [currentUser, loading, navigate]);

  const handleLogin = () => {
    login();
  };

  if (loading) {
    return <div className="fullpage-loading"></div>;
  }

  return (
    <div>
      <h1>Welcome to Songbooks</h1>
      {!currentUser && (
        <div>
          <p>Please log in to access your songbooks.</p>
          <button onClick={handleLogin}>Login</button>
        </div>
      )}
    </div>
  );
};

export default Landing;
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Landing from "./Landing";
import PrivateRoute from "./PrivateRoute";
import Home from './Home';
import { AuthProvider, useAuth } from "./AuthContext";
import DevLogin from "./DevLogin"; // You'll need to create this component

function AppContent() {
  const { showDevLogin, isLocalhost } = useAuth();

  return (
    <>
      {isLocalhost && showDevLogin && <DevLogin />}
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/home" element={<PrivateRoute><Home /></PrivateRoute>} />
        </Routes>
      </Router>
    </>
  );
}

function App() {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
}

export default App;
import React, { useState, useRef, useEffect } from 'react';

const EditableText = ({ text, onSave, isEditing, onEdit, onFinishEdit }) => {
  const [currentText, setCurrentText] = useState(text);
  const inputRef = useRef(null);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isEditing]);

  const handleBlur = () => {
    onEdit(false);
    if (currentText !== text) {
      onSave(currentText);
      onFinishEdit && onFinishEdit(); // Notify that editing has finished
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleBlur();
    } else if (event.key === 'Escape') {
      onEdit(false);
      setCurrentText(text);
    }
  };

  return isEditing ? (
    <input
      ref={inputRef}
      type="text"
      value={currentText}
      onChange={(e) => setCurrentText(e.target.value)}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
    />
  ) : (
    <span>{text}</span>
  );
};

export default EditableText;

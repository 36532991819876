import React, { useState } from "react";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";

const SortableItem = sortableElement(({ item, renderItem, onItemClick, isDragging }) => {
  return (
    <div 
      onClick={(e) => {
        e.stopPropagation();
        onItemClick(item);
      }}
      className={isDragging ? 'dragging' : ''}
    >
      {renderItem(item, isDragging)}
    </div>
  );
});

const SortableList = sortableContainer(({ items, renderItem, onItemClick, isDragging }) => {
  return (
    <div>
      {items.map((item, index) => (
        <SortableItem 
          key={`item-${item.id}`} 
          index={index} 
          item={item} 
          renderItem={renderItem}
          onItemClick={onItemClick}
          isDragging={isDragging}
        />
      ))}
    </div>
  );
});

const SortableObject = ({ 
  items, 
  setItems, 
  onSortEnd, 
  renderItem, 
  onItemClick,
  setSelectedItem,
  setIsAllItemsSelected,
  fetchItems
}) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleSortStart = () => {
    setIsDragging(true);
  };

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    setIsDragging(false);
    const newItems = arrayMoveImmutable(items, oldIndex, newIndex);
    setItems(newItems);
    onSortEnd(newItems);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setIsAllItemsSelected(false);
    fetchItems(item.id);
    if (onItemClick) {
      onItemClick(item);
    }
  };

  // Wrap the renderItem function to pass the isDragging state
  const wrappedRenderItem = (item, itemIsDragging) => {
    return renderItem(item, itemIsDragging || isDragging);
  };

  return (
    <SortableList 
      items={items} 
      onSortStart={handleSortStart}
      onSortEnd={handleSortEnd} 
      renderItem={wrappedRenderItem}
      onItemClick={handleItemClick}
      isDragging={isDragging}
      distance={5}
    />
  );
};

export default SortableObject;
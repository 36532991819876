import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faCheck } from '@fortawesome/free-solid-svg-icons';

const PopupMenu = ({ items, position, onClose, isSubmenu = false, useParentMode = false, parentRef = null, direction = 'below', checkable = false }) => {
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const menuRef = useRef(null);
  const menuItemRefs = useRef([]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  useEffect(() => {
    if (menuRef.current) {
      let { x, y } = position;

      if (useParentMode && parentRef && parentRef.current) {
        const parentRect = parentRef.current.getBoundingClientRect();
        x = parentRect.left;

        if (direction === 'above') {
          y = parentRect.top - menuRef.current.offsetHeight;
        } else {
          y = parentRect.bottom;
        }
      }

      const menuRect = menuRef.current.getBoundingClientRect();
      const { innerWidth, innerHeight } = window;

      if (x + menuRect.width > innerWidth) {
        x = innerWidth - menuRect.width - 5;
      }

      if (y + menuRect.height > innerHeight) {
        y = innerHeight - menuRect.height - 5;
      }

      menuRef.current.style.left = `${x}px`;
      menuRef.current.style.top = `${y}px`;
    }
  }, [position, isSubmenu, useParentMode, parentRef, direction]);

  const handleItemClick = (item, index) => {
    if (checkable) {
      item.action();
    } else if (item.action) {
      item.action();
      onClose();
    }
  };

  const handleSubMenuEnter = (index) => {
    setActiveSubMenu(index);
  };

  const renderMenuItem = (item, index) => {
    if (item.type === 'separator') {
      return <div key={index} className="menu-separator" />;
    }

    const isDisabled = item.disabled === true;

    return (
      <button
        key={index}
        ref={el => menuItemRefs.current[index] = el}
        className={`menu-item ${isDisabled ? 'disabled' : ''}`}
        onClick={() => !isDisabled && handleItemClick(item, index)}
        onMouseEnter={() => !isDisabled && handleSubMenuEnter(index)}
        disabled={isDisabled}
      >
        <div className="menu-label">
          {checkable && (
            <div className="checkable-space">
              {item.checked && <FontAwesomeIcon icon={faCheck} />}
            </div>
          )}
          <div>{item.label}</div>
        </div>
        {item.items && <FontAwesomeIcon icon={faChevronRight} />}
        {item.items && !isDisabled && activeSubMenu === index && (
          <PopupMenu
            items={item.items}
            position={getSubMenuPosition(index)}
            onClose={() => setActiveSubMenu(null)}
            isSubmenu={true}
            parentRef={menuRef}
            checkable={checkable}
          />
        )}
      </button>
    );
  };

  const getSubMenuPosition = (index) => {
    const parentItem = menuItemRefs.current[index];
    if (parentItem && menuRef.current) {
      const parentMenuRect = menuRef.current.getBoundingClientRect();
      const parentItemRect = parentItem.getBoundingClientRect();
      const { innerWidth, innerHeight } = window;

      const computedStyle = window.getComputedStyle(menuRef.current);
      const menuPadding = parseFloat(computedStyle.paddingBottom);

      const subMenuWidth = 200;
      const subMenuHeight = items[index].items.length * parentItemRect.height + (menuPadding * 2);

      let x = parentMenuRect.width;
      let y = parentItemRect.top - parentMenuRect.top - menuPadding;

      if (parentItemRect.right + subMenuWidth > innerWidth) {
        x = -subMenuWidth - (menuPadding * 2);
      }

      if (parentItemRect.bottom + subMenuHeight > innerHeight) {
        y = y + parentItemRect.height - subMenuHeight;
      }

      return { x, y };
    }
    return { x: 0, y: 0 };
  };

  return (
    <div
      ref={menuRef}
      className="context-menu"
      style={{
        position: isSubmenu ? 'absolute' : 'fixed',
        zIndex: 1000,
        backdropFilter: 'blur(5px)',
      }}
    >
      {items.map(renderMenuItem)}
    </div>
  );
};

export default PopupMenu;

import React, { useState, useEffect, useCallback, useRef } from "react";
import { collection, addDoc, getDoc, getDocs, query, where, updateDoc, deleteDoc, doc, setDoc, arrayUnion } from "firebase/firestore";
import { dbSongbooks } from "./firebase";
import { useAuth } from "./AuthContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faBookOpen, faPlus, faChevronLeft, faLinesLeaning, faCaretDown, faCaretUp, faUserCircle, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faBookBlank, faBuildingColumns, faChevronRight, faSidebar } from '@fortawesome/pro-regular-svg-icons';
import './App.css';
import songbooksLogo from './images/songbooks-logo.svg';
import PopupMenu from './PopupMenu';
import SongbookFilter from './SongbookFilter';
import EditableText from './EditableText';
import SortableObject from './SortableObject';

const SongbooksViewer = () => {
  const { currentUser } = useAuth();
  const [songbooks, setSongbooks] = useState([]);
  const [selectedSongbook, setSelectedSongbook] = useState(null);
  const [songs, setSongs] = useState([]);
  const [allSongs, setAllSongs] = useState([]);
  const [availableSongbooks, setAvailableSongbooks] = useState([]);
  const [currentSongId, setCurrentSongId] = useState(null);
  const [isAllSongsSelected, setIsAllSongsSelected] = useState(true);
  const [contextMenu, setContextMenu] = useState(null);
  const [newSongContextMenu, setNewSongContextMenu] = useState(null);
  const [accountMenu, setAccountMenu] = useState(null);
  const [isSidebarHidden, setIsSidebarHidden] = useState(false);
  const [editingSongbookId, setEditingSongbookId] = useState(null);
  const [editingSongId, setEditingSongId] = useState(null);
  const [isCollectionsVisible, setIsCollectionsVisible] = useState(true);
  const [isMySongbooksVisible, setIsMySongbooksVisible] = useState(true);
  const [isSharedWithMeVisible, setIsSharedWithMeVisible] = useState(true);
  const [columnVisibility, setColumnVisibility] = useState({
    Custom: true,
    Artist: true,
    Key: true,
    Time: true,
    Tempo: true,
    Duration: true,
  });
  const sortableTitlesRef = useRef(null);
  const [headerColumnRects, setHeaderColumnRects] = useState({});
  const [selectedSorting, setSelectedSorting] = useState({ column: 'Title', direction: 'ascending' });

  const { logout } = useAuth();
  const userIconRef = useRef(null);
  const selectViewRef = useRef(null);

  const initializeUserPrefs = async () => {
    if (currentUser) {
      const userPrefsRef = doc(dbSongbooks, 'userprefs', currentUser.uid);
      const userPrefsDoc = await getDoc(userPrefsRef);

      if (!userPrefsDoc.exists()) {
        try {
          await setDoc(userPrefsRef, {
            mySongbooksSortOrder: []
          });
        } catch (error) {
          console.error("Error initializing user preferences:", error);
          alert('Failed to initialize user preferences. Please try again later.');
        }
      } else {
        const sortOrder = userPrefsDoc.data().mySongbooksSortOrder || [];
        return sortOrder;
      }
    }
    return [];
  };

  const fetchSongbooks = useCallback(async (sortOrder) => {
    if (currentUser) {
      try {
        const q = query(collection(dbSongbooks, 'songbooks'), where('userId', '==', currentUser.uid));
        const querySnapshot = await getDocs(q);
        let songbooksList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        if (sortOrder && sortOrder.length > 0) {
          songbooksList.sort((a, b) => sortOrder.indexOf(a.id) - sortOrder.indexOf(b.id));
        }

        setSongbooks(songbooksList);
      } catch (error) {
        console.error("Error fetching songbooks:", error);
        alert('Failed to fetch songbooks. Please try again later.');
      }
    } else {
      console.log("No current user detected.");
    }
  }, [currentUser]);

  const fetchSongs = useCallback(async (songbookId) => {
    if (currentUser) {
      try {
        const q = query(collection(dbSongbooks, 'songs'), where('songbookIds', 'array-contains', songbookId));
        const querySnapshot = await getDocs(q);
        const songsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setSongs(songsList);
      } catch (error) {
        console.error("Error fetching songs:", error);
        alert('Failed to fetch songs. Please try again later.');
      }
    } else {
      console.log("No current user detected.");
    }
  }, [currentUser]);

  const fetchAllSongs = useCallback(async () => {
    if (currentUser) {
      try {
        const q = query(collection(dbSongbooks, 'songs'), where('userId', '==', currentUser.uid));
        const querySnapshot = await getDocs(q);
        const songsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setAllSongs(songsList);
        setSongs(songsList);
      } catch (error) {
        console.error("Error fetching all songs:", error);
        alert('Failed to fetch all songs. Please try again later.');
      }
    } else {
      console.log("No current user detected.");
    }
  }, [currentUser]);

  useEffect(() => {
    const initialize = async () => {
      const sortOrder = await initializeUserPrefs();
      fetchSongbooks(sortOrder);
      fetchAllSongs();
    };

    initialize();
    updateHeaderColumnRects();

    const handleResize = () => {
      updateHeaderColumnRects();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [fetchSongbooks, fetchAllSongs]);


  const createSongbook = async () => {
    const songbookName = "Untitled";
    if (currentUser) {
      try {
        const docRef = await addDoc(collection(dbSongbooks, 'songbooks'), { name: songbookName, userId: currentUser.uid });
        const newSongbook = { id: docRef.id, name: songbookName };
        setSongbooks([...songbooks, newSongbook]);
        setEditingSongbookId(docRef.id);
      } catch (error) {
        console.error("Error adding songbook:", error);
        alert('Failed to create songbook. Please try again later.');
      }
    } else {
      console.log("No current user.");
    }
  };

  const handleFinishEdit = (songbook) => {
    setSelectedSongbook(songbook);
    setIsAllSongsSelected(false);
    fetchSongs(songbook.id);
  };

  const createSong = async (songbookId = null) => {
    const songName = "Untitled";
    if (currentUser) {
      try {
        const songData = {
          name: songName,
          userId: currentUser.uid,
          songbookIds: songbookId ? [songbookId] : []
        };
        const docRef = await addDoc(collection(dbSongbooks, 'songs'), songData);
        const newSong = { id: docRef.id, ...songData };
        setSongs([...songs, newSong]);
        setAllSongs([...allSongs, newSong]);
        setEditingSongId(docRef.id);
        if (songbookId) {
          fetchSongs(songbookId);
        } else {
          fetchAllSongs();
        }
      } catch (error) {
        console.error("Error adding song:", error);
        alert('Failed to create song. Please try again later.');
      }
    } else {
      console.log("No current user.");
    }
  };

  const fetchAvailableSongbooks = async (songId) => {
    try {
      const q = query(collection(dbSongbooks, 'songbooks'), where('userId', '==', currentUser.uid));
      const querySnapshot = await getDocs(q);
      const songbooksList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      const filteredSongbooks = songbooksList.filter(songbook =>
        !songs.find(song => song.id === songId)?.songbookIds?.includes(songbook.id)
      );

      setAvailableSongbooks(filteredSongbooks);
    } catch (error) {
      console.error("Error fetching available songbooks:", error);
      alert('Failed to fetch available songbooks. Please try again later.');
    }
  };

  const addSongToSongbook = async (songId, songbookId) => {
    try {
      const songRef = doc(dbSongbooks, 'songs', songId);
      await updateDoc(songRef, {
        songbookIds: arrayUnion(songbookId)
      });
      await fetchAvailableSongbooks(songId);
      if (isAllSongsSelected) {
        fetchAllSongs();
      } else {
        fetchSongs(selectedSongbook.id);
      }
    } catch (error) {
      console.error("Error adding song to songbook:", error);
      alert('Failed to add song to songbook. Please try again later.');
    }
  };

  const removeSongFromSongbook = async (songId, songbookId) => {
    try {
      const songRef = doc(dbSongbooks, 'songs', songId);
      const songDoc = await getDoc(songRef);

      if (songDoc.exists) {
        const songData = songDoc.data();
        const updatedSongbookIds = songData.songbookIds.filter(id => id !== songbookId);

        await updateDoc(songRef, {
          songbookIds: updatedSongbookIds
        });

        if (isAllSongsSelected) {
          fetchAllSongs();
        } else {
          fetchSongs(selectedSongbook.id);
        }
      } else {
        console.error("No such song document!");
      }
    } catch (error) {
      console.error("Error removing song from songbook:", error);
      alert('Failed to remove song from songbook. Please try again later.');
    }
  };

  const deleteItem = async (id, type) => {
    try {
      await deleteDoc(doc(dbSongbooks, type === 'song' ? `songs/${id}` : `songbooks/${id}`));
      if (type === "song") {
        setSongs(songs.filter(song => song.id !== id));
        setAllSongs(allSongs.filter(song => song.id !== id));
      } else if (type === "songbook") {
        setSongbooks(songbooks.filter(songbook => songbook.id !== id));

        if (selectedSongbook && selectedSongbook.id === id) {
          setSelectedSongbook(null);
          setIsAllSongsSelected(true);
          setSongs(allSongs);
        }
      }
    } catch (error) {
      console.error("Error deleting item:", error);
      alert('Failed to delete item. Please try again later.');
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error("Error logging out:", error);
      alert('Failed to log out. Please try again later.');
    }
  };

  const handleContextMenu = async (event, id, type) => {
    event.preventDefault();
    setCurrentSongId(type === "song" ? id : null);

    if (type === "song") {
      await fetchAvailableSongbooks(id);
    }

    setContextMenu({
      position: { x: event.clientX, y: event.clientY },
      id,
      type
    });
  };

  const handleSortableTitlesContextMenu = (event) => {
    event.preventDefault();
    setContextMenu({
      position: { x: event.clientX, y: event.clientY },
      type: 'columns'
    });
  };

  const handleNewSongContextMenu = (event) => {
    event.preventDefault();
    setNewSongContextMenu({
      position: { x: event.clientX, y: event.clientY }
    });
  };

  const getNewSongContextMenuItems = () => [
    { label: 'New Song', action: () => createSong(selectedSongbook?.id) }
  ];

  const handleAccountClick = (event) => {
    event.preventDefault();
    setAccountMenu(prev => prev ? null : { position: {} });
  };

  const toggleSidebar = () => {
    setIsSidebarHidden(!isSidebarHidden);
  };

  const toggleCategoryVisibility = (category) => {
    switch (category) {
      case 'collections':
        setIsCollectionsVisible(!isCollectionsVisible);
        break;
      case 'mySongbooks':
        setIsMySongbooksVisible(!isMySongbooksVisible);
        break;
      case 'sharedWithMe':
        setIsSharedWithMeVisible(!isSharedWithMeVisible);
        break;
      default:
        break;
    }
  };

  const songCountText = (count) => {
    return `${count} ${count === 1 ? 'song' : 'songs'}`;
  };

  const renameSongbook = async (id, newName) => {
    try {
      const songbookRef = doc(dbSongbooks, 'songbooks', id);
      await updateDoc(songbookRef, { name: newName });
      fetchSongbooks();
    } catch (error) {
      console.error("Error renaming songbook:", error);
      alert('Failed to rename songbook. Please try again later.');
    }
  };

  const renameSong = async (id, newName) => {
    try {
      const songRef = doc(dbSongbooks, 'songs', id);
      await updateDoc(songRef, { name: newName });
      fetchAllSongs();
    } catch (error) {
      console.error("Error renaming song:", error);
      alert('Failed to rename song. Please try again later.');
    }
  };

  const getContextMenuItems = () => {
    if (contextMenu?.type === 'song') {
      const addToSongbookSubmenu = availableSongbooks.length > 0
        ? availableSongbooks.map(songbook => ({
            label: songbook.name,
            action: () => addSongToSongbook(contextMenu.id, songbook.id)
          }))
        : [{ label: 'No Songbooks available', disabled: true }];

      const menuItems = [
        {
          label: 'Add to Songbook',
          items: addToSongbookSubmenu
        },
        { type: 'separator' },
        { label: 'Rename Song', action: () => setEditingSongId(contextMenu.id) },
        { label: 'Delete Song', action: () => deleteItem(contextMenu.id, 'song') }
      ];

      if (selectedSongbook) {
        menuItems.splice(1, 0, {
          label: `Remove from ${selectedSongbook.name}`,
          action: () => removeSongFromSongbook(contextMenu.id, selectedSongbook.id)
        });
      }

      return menuItems;
    } else if (contextMenu?.type === 'songbook') {
      return [
        { label: 'Rename Songbook', action: () => setEditingSongbookId(contextMenu.id) },
        { label: 'Delete Songbook', action: () => deleteItem(contextMenu.id, 'songbook') }
      ];
    } else if (contextMenu?.type === 'columns') {
      return getColumnToggleItems();
    }
    return [];
  };

  const accountMenuItems = [
    { label: 'Manage', action: () => console.log('Manage clicked') },
    { label: 'Log out', action: handleLogout },
  ];

  const handleSelect = (selectedValue) => {
    if (selectedValue === "full-songbook") {
      setSelectedSongbook(null);
    } else {
      const selected = songbooks.find((songbook) => songbook.value === selectedValue);
      setSelectedSongbook(selected);
    }
  };

  const handleSortEnd = async (newOrder) => {
    setSongbooks(newOrder);
    if (currentUser) {
      try {
        const userPrefsRef = doc(dbSongbooks, 'userprefs', currentUser.uid);
        await updateDoc(userPrefsRef, { mySongbooksSortOrder: newOrder.map(songbook => songbook.id) });
      } catch (error) {
        console.error("Error updating songbook order:", error);
        alert('Failed to update songbook order. Please try again later.');
      }
    }
  };

  const toggleColumnVisibility = (column) => {
    setColumnVisibility(prevState => {
      const newState = {
        ...prevState,
        [column]: !prevState[column]
      };
      updateHeaderColumnRects();
      return newState;
    });
  };

const getColumnToggleItems = () => {
  const columns = ['Custom', 'Artist', 'Key', 'Tempo', 'Time', 'Duration'];

  const columnItems = columns.map(column => ({
    label: column,
    action: () => toggleColumnVisibility(column),
    checked: columnVisibility[column],
  }));

  const additionalItems = [
    { type: 'separator' },
    {
      label: 'Songbook Tag',
      items: [
        { label: 'Songbook Tag 1', action: () => console.log('Songbook Tag 1 selected') },
        { label: 'Songbook Tag 2', action: () => console.log('Songbook Tag 2 selected') },
        { type: 'separator' },
        { label: 'New Tag', action: () => console.log('New Songbook Tag') },
      ]
    },
    {
      label: 'Global Tag',
      items: [
        { label: 'Global Tag 1', action: () => console.log('Global Tag 1 selected') },
        { label: 'Global Tag 2', action: () => console.log('Global Tag 2 selected') },
        { type: 'separator' },
        { label: 'New Tag', action: () => console.log('New Global Tag') },
      ]
    }
  ];

  return [...columnItems, ...additionalItems];
};


const handleSortClick = (column) => {
  setSelectedSorting((prevState) => {
    const newState = {
      column,
      direction: prevState.column === column && prevState.direction === 'ascending' ? 'descending' : 'ascending'
    };
    updateHeaderColumnRects();
    return newState;
  });
};


const updateHeaderColumnRects = () => {
  if (sortableTitlesRef.current) {
    const updatedHeaderColumnRects = {};
    sortableTitlesRef.current.querySelectorAll('.sortable-title').forEach(column => {
      const columnName = column.getAttribute('data-column');
      updatedHeaderColumnRects[columnName] = column.getBoundingClientRect();
    });
    setHeaderColumnRects(updatedHeaderColumnRects);
  }
};

const getSelectViewMenuItems = () => [
  { label: 'Songs', action: () => handleSelectViewOption('Songs'), checked: true },
  { label: 'Setlists', action: () => handleSelectViewOption('Setlists') },
  { type: 'separator' },
  {
    label: 'Custom View',
    items: [
      { label: 'New View', action: () => handleSelectViewOption('New View') }
    ]
  }
];

const handleSelectViewOption = (option) => {
  // Implement the logic to handle the select view option
  console.log(`Selected view option: ${option}`);
};

return (
    <div className="songbooksviewer">
      <div className={`sidebar ${isSidebarHidden ? 'hidden' : ''}`}>
        <div className="sidebar-top">
          <div className="sidebar-header">
            <div className="sidebar-toolbar-container">
              <FontAwesomeIcon className="toolbar-button togglesidebar" onClick={toggleSidebar} icon={faSidebar} />
            </div>
            <div className="songbooks-logo">
              <img src={songbooksLogo} alt="Songbooks Logo" />
            </div>
            <div className="sidebar-toolbar-container">
                          <FontAwesomeIcon
                className="toolbar-button account"
                onClick={handleAccountClick}
                icon={faUserCircle}
                ref={userIconRef}
              />
            </div>
          </div>
          <div className="sidebar-lists">
            <div className="sidebar-category" onClick={() => toggleCategoryVisibility('collections')}>
              <div>Collections</div>
              <FontAwesomeIcon className="sidebar-category-chevron" icon={isCollectionsVisible ? faChevronDown : faChevronRight} />
            </div>
            {isCollectionsVisible && (
              <div className="category-content">
                <li>
                  <FontAwesomeIcon className="list-icon list-icon-gray" icon={faBuildingColumns} /> Complete Library
                </li>
                <li
                  onClick={() => { setSelectedSongbook(null); setIsAllSongsSelected(true); setSongs(allSongs); }}
                  className={isAllSongsSelected ? 'selected' : ''}
                >
                  <FontAwesomeIcon className="list-icon" icon={faLinesLeaning} /> My Songs
                </li>
              </div>
            )}
            <div className="sidebar-category" onClick={() => toggleCategoryVisibility('mySongbooks')}>
              <div>My Songbooks</div>
              <FontAwesomeIcon className="sidebar-category-chevron" icon={isMySongbooksVisible ? faChevronDown : faChevronRight} />
            </div>
            {isMySongbooksVisible && (
              <div className="category-content">
                <SortableObject
                  items={songbooks}
                  setItems={setSongbooks}
                  onSortEnd={handleSortEnd}
                  setSelectedItem={setSelectedSongbook}
                  setIsAllItemsSelected={setIsAllSongsSelected}
                  fetchItems={fetchSongs}
                  renderItem={(songbook, isDragging) => (
                    <div
                      key={songbook.id}
                      onContextMenu={(e) => handleContextMenu(e, songbook.id, "songbook")}
                      className={`sortable-songbook 
                        ${selectedSongbook && selectedSongbook.id === songbook.id ? 'selected' : ''}
                        ${isDragging ? 'list-is-dragging' : ''}`}
                    >
                      <FontAwesomeIcon 
                        className="list-icon" 
                        icon={selectedSongbook && selectedSongbook.id === songbook.id ? faBookOpen : faBookBlank} 
                      />
                      <EditableText
                        text={songbook.name}
                        onSave={(newName) => renameSongbook(songbook.id, newName)}
                        isEditing={editingSongbookId === songbook.id}
                        onEdit={(isEditing) => setEditingSongbookId(isEditing ? songbook.id : null)}
                        onFinishEdit={() => handleFinishEdit(songbook)}
                        isDragging={isDragging}
                      />
                    </div>
                  )}
                />
                <li className="new-songbook" onClick={createSongbook}>
                  <FontAwesomeIcon className="list-icon list-icon-new" icon={faPlus} />New
                </li>
              </div>
            )}
            <div className="sidebar-category" onClick={() => toggleCategoryVisibility('sharedWithMe')}>
              <div>Shared With Me</div>
              <FontAwesomeIcon className="sidebar-category-chevron" icon={isSharedWithMeVisible ? faChevronDown : faChevronRight} />
            </div>
            {isSharedWithMeVisible && (
              <div className="category-content">
                <ul className="shared-songbooks">
                  <li className="shared-songbook">
                    <FontAwesomeIcon className="list-icon list-icon-gray" icon={faBookBlank} />None yet
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="sidebar-footer">
        </div>
      </div>
      <div className="view">
        <header className="view-toolbar">
          <div className="toolbar-group">
            {isSidebarHidden && (
              <FontAwesomeIcon className="toolbar-button back" onClick={toggleSidebar} icon={faChevronLeft} />
            )}
            <div className={`title-stack`}>
              <div className="title-row">
                <h1>
                  {isAllSongsSelected ? "My Songs" : selectedSongbook ? selectedSongbook.name : ""}
                </h1>
                <FontAwesomeIcon icon={faCaretRight} />
                <div className="select-view" onClick={(e) => setContextMenu({ position: { x: e.clientX, y: e.clientY }, type: 'select-view' })} ref={selectViewRef}>
                  <span>Songs</span>
                  <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: 'var(--font-size-tiny)' }} />
                </div>
              </div>
              <p className="subtitle">{songCountText(songs.length)}</p>
            </div>
          </div>
          <div className="toolbar-group">
            <FontAwesomeIcon className="toolbar-button view" onClick={() => createSong(selectedSongbook?.id)} icon={faPlus} />
          </div>
        </header>
        <div className="sortable-titles" ref={sortableTitlesRef} onContextMenu={handleSortableTitlesContextMenu}>
          {columnVisibility.Custom && (
            <div
              className={`sortable-title fixed ${selectedSorting.column === 'Custom' ? 'selected' : ''}`}
              data-column="Custom"
              onClick={() => handleSortClick('Custom')}
            >
              <h2>#</h2>
              {selectedSorting.column === 'Custom' && (
                <FontAwesomeIcon className="sort-direction" icon={selectedSorting.direction === 'ascending' ? faCaretUp : faCaretDown} />
              )}
            </div>
          )}
          <div
            className={`sortable-title ${selectedSorting.column === 'Title' ? 'selected' : ''}`}
            data-column="Title"
            onClick={() => handleSortClick('Title')}
          >
            <div className="sortable-title-divider"></div>
            <h2>Title</h2>
            {selectedSorting.column === 'Title' && (
              <FontAwesomeIcon className="sort-direction" icon={selectedSorting.direction === 'ascending' ? faCaretUp : faCaretDown} />
            )}
          </div>
          {Object.keys(columnVisibility).map(column => columnVisibility[column] && column !== 'Custom' && (
            <div
              key={column}
              className={['Key', 'Time', 'Tempo', 'Duration'].includes(column) ? `sortable-title fixed ${selectedSorting.column === column ? 'selected' : ''}` : `sortable-title ${selectedSorting.column === column ? 'selected' : ''}`}
              data-column={column}
              onClick={() => handleSortClick(column)}
            >
              <div className="sortable-title-divider"></div>
              <h2>{column}</h2>
              {selectedSorting.column === column && (
                <FontAwesomeIcon className="sort-direction" icon={selectedSorting.direction === 'ascending' ? faCaretUp : faCaretDown} />
              )}
            </div>
          ))}
        </div>
        <div className="view-content">
          {songs.map(song => (
            <div className="item-container" key={song.id}>
              <div className="item" onContextMenu={(e) => handleContextMenu(e, song.id, "song")}>
                {columnVisibility.Custom && <div className="item-secondary-info" style={{ width: `${headerColumnRects['Custom']?.width || 0}px` }}><span className="secondary-content"></span></div>}
                <div className="item-primary-info" style={{ width: `${headerColumnRects['Title']?.width || 0}px` }}>
                  <span className="align-first-column">
                    <EditableText
                      text={song.name}
                      onSave={(newName) => renameSong(song.id, newName)}
                      isEditing={editingSongId === song.id}
                      onEdit={(isEditing) => setEditingSongId(isEditing ? song.id : null)}
                    />
                  </span>
                </div>
                {columnVisibility.Artist && <div className="item-secondary-info string" style={{ width: `${headerColumnRects['Artist']?.width || 0}px` }}><span className="secondary-content">Artist</span></div>}
                {columnVisibility.Key && <div className="item-secondary-info" style={{ width: `${headerColumnRects['Key']?.width || 0}px` }}><span className="secondary-content">C</span></div>}
                {columnVisibility.Time && <div className="item-secondary-info" style={{ width: `${headerColumnRects['Time']?.width || 0}px` }}><span className="secondary-content"><div className="time-signature-format"><div>4</div><div>4</div></div></span></div>}
                {columnVisibility.Tempo && <div className="item-secondary-info" style={{ width: `${headerColumnRects['Tempo']?.width || 0}px` }}><span className="secondary-content">120</span></div>}
                {columnVisibility.Duration && <div className="item-secondary-info" style={{ width: `${headerColumnRects['Duration']?.width || 0}px` }}><span className="secondary-content">3:30</span></div>}
              </div>
            </div>
          ))}
          <div className="view-content-emptyspace" onContextMenu={handleNewSongContextMenu}></div>
        </div>
      </div>
      {contextMenu && (
        <PopupMenu
          items={contextMenu.type === 'select-view' ? getSelectViewMenuItems() : getContextMenuItems()}
          position={contextMenu.position}
          onClose={() => setContextMenu(null)}
          checkable={contextMenu?.type === 'columns'}
          useParentMode={contextMenu?.type === 'select-view'}
          parentRef={selectViewRef}
        />
      )}
      {newSongContextMenu && (
        <PopupMenu
          items={getNewSongContextMenuItems()}
          position={newSongContextMenu.position}
          onClose={() => setNewSongContextMenu(null)}
        />
      )}
      {accountMenu && (
        <PopupMenu
          items={accountMenuItems}
          position={{}}
          onClose={() => setAccountMenu(null)}
          useParentMode={true}
          parentRef={userIconRef}
        />
      )}
    </div>
  );
};

export default SongbooksViewer;
